.comp-title {
  width: calc(1920px - 17px);
  overflow: hidden;
  text-align: center;
  height: 240px;
  font-size: 36px;
  font-weight: bolder;
  color: #333;
  line-height: 240px;
  font-family: Anderson The Secret Service;
}
