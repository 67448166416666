.index {
  width: calc(1920px - 17px);
  overflow: hidden;
  .top {
    position: relative;
    width: 1920px;
    // height: 960px;
    height: 480px;
    overflow: hidden;
    .top-bg {
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 1920px;
      height: 480px;
    }
    .prev,
    .next {
      position: absolute;
      // top: 483px;
      top: 241px;
      width: 64px;
      height: 64px;
    }
    .prev {
      left: 37px;
    }
    .next {
      right: 37px;
    }
    @keyframes move {
      0% {
        left: 1920px;
      }
      100% {
        left: 186px;
      }
    }
    .top-line-bg {
      position: absolute;
      top: 0;
      left: 186px;
      width: 0px;
      height: 0px;
      z-index: 99;
      animation: move 1.2s ease-in-out 0s 1;
      .top-line-bg-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height:0px;
      }
      .top-line-text {
        position: absolute;
        // top: 325px;
        top: 180px;
        left: 60px;
        font-size: 47px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .top-line-btn {
        position: absolute;
        // top: 600px;
        top: 350px;
        left: 63px;
        width: 262px;
        height: 65px;
        line-height: 65px;
        text-align: center;
        border-radius: 32px;
        color: #EA9C4C;
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .zhan{
    height: 100px;
  }

  .craete {
    position: relative;
    width: 1920px;
    height: 720px;
    .text-cont {
      margin: 0 auto;
      width: 1122px;
      height: 123px;
    }
    .dot-img {
      position: absolute;
      top: -146px;
      left: 76px;
      width: 387px;
      height: 363px;
    }
    .pian-img {
      position: absolute;
      top: 255px;
      left: 10px;
      width: 248px;
      height: 296px;
    }
	.pian-img1 {
	  position: absolute;
	  top: 2615px;
	  right: 10px;
	  width: 248px;
	  height: 302px;
	}
    .main-img {
      margin: 0 auto;
      display: block;
      width: 1442px;
      height: 618px;
    }
  }

  .solution {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 1424px;
    height: auto;
    .solution-item {
      position: relative;
      margin-bottom: 60px;
      width: 450px;
      height: 512px;
      background-color: #f7f7f7;
      &:hover {
        background-color: #ea9c4b;
      }
      .so-img {
        display: block;
        margin: 66px auto;
        width: 110px;
        height: 94px;
      }
      // &:nth-child(3) > .so-img {
      //   width: 55px;
      //   height: 99px;
      // }
      .title {
        margin-top: 72px;
        text-align: center;
        font-size: 22px;
        font-family: Arial;
        font-weight: 400;
        color: #333333;
      }
      .cont {
        margin: 30px auto 0;
        width: 359px;
        height: 141px;
        // font-size: 24px;
        font-size: 22px;
        font-family: Calibri;
        font-weight: 400;
        color: #666666;
        opacity: 0.8;
      }
    }
  }

  .choose-create {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    width: 1920px;
    height: 556px;
    background-color: #f8f8f8;
    .cc-item {
      border-radius: 20px;
      width: 335px;
      height: 470px;
      margin: 10px;
      background-color: #6b6b6b;
      // &:nth-child(1),
      // &:nth-child(3) {
      //   background-color: #ea9c4b;
      // }
      .order {
        line-height: 200px;
        text-align: center;
        font-size: 70px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.49;
      }
      .content {
        margin: 0 auto;
        width: 270px;
        height: 196px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
  }

  .client {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1420px;
    height: 200px;
    .client-img {
      &:nth-child(2) {
        width: 232px;
        height: 85px;
      }
      &:nth-child(3) {
        width: 243px;
        height: 69px;
      }
      &:nth-child(4) {
        width: 266px;
        height: 48px;
      }
      &:nth-child(5) {
        width: 168px;
        height: 95px;
      }
    }

    .after {
      width: 45px;
      height: 45px;
    }
    .before {
      width: 45px;
      height: 45px;
    }
  }
  .news {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1420px;
    height: 468px;
    .news-item {
      width: 451px;
      height: 450px;
      border-radius: 10px;
      .news-img {
        width: 451px;
        height: 285px;
      }
      .news-tit {
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 85px;
      }
      .news-txt {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
       

                overflow:hidden;

                white-space: nowrap;

                text-overflow: ellipsis;

                -o-text-overflow:ellipsis;
      }
    }
  }
  .dot {
   
    position: absolute;
    right: 250px;
    top: 4375px;
    a {
      text-decoration: none;
      color: #000;
    }
    // div {
    //   margin: 20px;
    //   width: 13px;
    //   height: 13px;
    //   background-color: #ccc;
    //   border-radius: 50%;
    //   &:nth-child(1) {
    //     background-color: #ea9c4b;
    //   }
    // }
  }
}


@font-face {
  font-family: 'iconfont';  /* Project id 3194462 */
  src: url('//at.alicdn.com/t/c/font_3194462_x4f03d7h6of.woff2?t=1666686379138') format('woff2'),
       url('//at.alicdn.com/t/c/font_3194462_x4f03d7h6of.woff?t=1666686379138') format('woff'),
       url('//at.alicdn.com/t/c/font_3194462_x4f03d7h6of.ttf?t=1666686379138') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-duosucai:before {
  content: "\e600";
}

.icon-qianjin1:before {
  content: "\e619";
}


