.about {
  width: calc(1920px - 17px);
  overflow: hidden;
  .top-cont {
    position: relative;
    width: 1920px;
    height: 500px;
    .common-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 500px;
    }
    .common-title {
      position: absolute;
      text-align: center;
      width: 1920px;
      padding-top: 281px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 3px 7px rgba(151, 151, 153, 0.5);
    }
  }

  .philosophy {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1420px;
    height: 580px;
    .philosophy-img {
      width: 448px;
      height: 580px;
    }
  }
  .cs{
    position: relative;
  }
  .tips{
    position: absolute;
    top: 220px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 30px;
    font-weight: 600;
    font-family: Microsoft YaHei;
  }
  .big-meng{
    display: none;
  }
  .cs:hover  .big-meng{
    display: block;
 }
  .tips1 {
    position: absolute;
    top: 291px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .tips2{
    position: absolute;
    top: 320px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .tips3{
    position: absolute;
    top: 352px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .tips4{
    position: absolute;
    top: 384px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .tips5{
    position: absolute;
    top: 416px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .tips6{
    position: absolute;
    top: 448px;
    width: 100%;
    text-align: center;
    color: #ea9c4b;
    font-size: 22px;
    // font-weight: 550;
    font-family: Microsoft YaHei;
   
  }
  .meng{
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
  }
  .zi{
    position: absolute;
    left: 9px;
    top: 86px;
    z-index: 999;
    font-size: 36px;
    font-weight: 600;
    font-family: Microsoft YaHei;
  }
  .map {
    position: relative;
    margin: 20px auto;
    width: 1420px;
    height: 796px;
    .map-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 1229px;
      height: 796px;
    }
    .map-cont {
      position: absolute;
      top: 158px;
      left: 533px;
      width: 888px;
      height: 219px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #494546;
      line-height: 40px;
    }
    .map-more {
      position: absolute;
      top: 585px;
      left: 772px;
      width: 281px;
      height: 74px;
      line-height: 74px;
      text-indent: 36px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      background-color: #ea9c4b;
    }
  }

  .team {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1420px;
    height: 468px;
    .team-box {
      width: 1420px;
      height: 468px;
    }
    .team-img-box {
      position: relative;
      width: 322px;
      height: 468px;
    }
    .team-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 322px;
      height: 468px;
    }
    .team-level {
      position: absolute;
      top: 370px;
      left: 0;
      width: 322px;
      height: auto;
      text-align: center;
      .team-level-value {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ea9c4c;
        line-height: 32px;
      }
      .team-level-name {
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ea9c4c;
        line-height: 33px;
      }
    }
    .team-level-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 322px;
      height: 468px;
      text-align: center;
      .team-level-hover-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 322px;
        height: 468px;
      }
      .team-level-hover-value {
        position: absolute;
        top: 160px;
        left: 21px;
        width: 280px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        .team-level-hover-division {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
        }
        .team-level-hover-name {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
        }
      }
    }
    .after {
      position: absolute;
      top: 271px;
      right: -50px;
      width: 45px;
      height: 45px;
    }
    .before {
      position: absolute;
      top: 271px;
      left: -50px;
      width: 45px;
      height: 45px;
    }
  }

  .news {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1420px;
    height: 468px;
    .news-item {
      width: 451px;
      height: 450px;
      border-radius: 10px;
      .news-img {
        width: 451px;
        height: 285px;
      }
      .news-tit {
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 85px;
      }
      .news-txt {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        overflow:hidden;

        white-space: nowrap;

        text-overflow: ellipsis;

        -o-text-overflow:ellipsis;
      }
    }
  }
  .dot {
    // width: 1920px;
    // display: flex;
    // justify-content: center;
    position: absolute;
    right: 250px;
    top: 2975px;
    a {
      text-decoration: none;
      color: #000;
    }
    // div {
    //   margin: 20px;
    //   width: 13px;
    //   height: 13px;
    //   background-color: #ccc;
    //   border-radius: 50%;
    //   &:nth-child(1) {
    //     background-color: #ea9c4b;
    //   }
    // }
  }
  .client {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1420px;
    height: 200px;
    .client-img {
      &:nth-child(2) {
        width: 232px;
        height: 85px;
      }
      &:nth-child(3) {
        width: 243px;
        height: 69px;
      }
      &:nth-child(4) {
        width: 266px;
        height: 48px;
      }
      &:nth-child(5) {
        width: 168px;
        height: 95px;
      }
    }

    .after {
      width: 45px;
      height: 45px;
    }
    .before {
      width: 45px;
      height: 45px;
    }
  }
}
