.solution {
  position: relative;
  width: calc(1920px - 17px);
  overflow: hidden;
  height: 14500px;
  .top-cont {
    position: relative;
    width: 1920px;
    height: 500px;
    .common-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 500px;
    }
    .common-title {
      position: absolute;
      text-align: center;
      width: 1920px;
      padding-top: 281px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 3px 7px rgba(151, 151, 153, 0.5);
    }
  }
  .kong{
    height: 6px;
  }
 

  .banner {
    position: relative;
    padding-top: 80px;
    @mixin t-a-c {
      text-align: center;
    }
    .banner-line1 {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      @include t-a-c;
    }
    .banner-line2 {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 100px;
      @include t-a-c;
    }
    .banner-line3 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 60px;
      @include t-a-c;
    }
    .banner-box {
      position: relative;
      margin: 0 auto;
      width: 1640px;
      height: 600px;
      .banner-btn-prev {
        position: absolute;
        top: 320px;
        left: 0;
        width: 46px;
        height: 46px;
        cursor: pointer;
      }
      .banner-btn-next {
        position: absolute;
        top: 320px;
        right: 0;
        width: 46px;
        height: 46px;
        cursor: pointer;
      }
      .banner-prev{
        transform: scale(0.65);
        transform-origin: left top;
        left: 110px;
        top: 120px;
        z-index: 99;
      }
      .banner-curr{
        z-index: 999;
        left: 245px;
        top: 0;
      }
      .banner-next{
        transform: scale(0.65);
        transform-origin: right top;
        right: 110px;
        top: 120px;
        transform: scale(0.65);
        z-index: 99;
      }
      .banner-item {
        // margin: 0 auto;
        position: absolute;
        width: 1150px;
        height: 600px;
        .banner-item-img {
          width: 1150px;
          height: 420px;
        }
        .banner-item-info {
          position: relative;
          width: 1150px;
          height: 180px;
          background-color: #ffffff;
          .banner-info-tit {
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #323232;
            line-height: 71px;
            text-indent: 40px;
          }
          .banner-info-txt {
            text-indent: 40px;
            font-size: 19px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #646464;
            line-height: 71px;
          }
          .banner-info-btn {
            position: absolute;
            top: 70px;
            right: 60px;
            width: 205px;
            height: 48px;
            line-height: 48px;
            @include t-a-c;
            border-radius: 24px;
            // border: 1px solid #dcdcdc;
            cursor: pointer;
          }
          .a-href {
            text-decoration: none;
            color: #333333;
          }
        }
      }
    }
  }
  .images-go{
    width: 131px;
    height: 19px;
  }


  .content {
    position: relative;
    // height: 1990px;
    .content-bg {
      position: absolute;
      left: 0;
      top: 70px;
      width: 1772px;
      height: 804px;
    }
    .solution-card:nth-child(4) {
      .title-icon {
        // width: 29px !important;
        height: 48px !important;
      }
    }
    .solution-card:nth-child(6) {
      .title-text {
        font-size: 32px;
      }
    }
  }
  .core {
    position: relative;
    width: 100%;
    height: 1102px;
    margin-top: 160px;
    background-color: #e9e9e9;
    .vertical-title {
      position: absolute;
      left: 0px;
      bottom: 330px;
      transform: rotate(-90deg);
      font-size: 51px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1e1e1e;
    }
    .core-content {
      position: absolute;
      top: -85px;
      right: 0;
      width: 1670px;
      height: 1075px;
      background-color: #ea9c4c;
      .core-tit-1 {
        position: absolute;
        top: 90px;
        left: 87px;
        font-size: 63px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
      .core-tit-2 {
        position: absolute;
        top: 174px;
        left: 87px;
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
      .b-bg {
        position: absolute;
        top: 282px;
        left: 86px;
        width: 675px;
        height: 664px;
      }
      .core-list {
        position: absolute;
        left: 826px;
        top: 282px;
        width: 711px;
        height: 664px;
        .core-list-item {
          width: 711px;
          height: 160px;
          .core-item-title {
            height: 170px;
            border-bottom: 1px solid #eeeeee;
            .core-item-title-num {
              font-size: 76px;
              font-family: STCaiyun;
              font-weight: 400;
              color: #ffffff;
              line-height: 1.5;
            }
            .core-item-title-txt {
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              line-height: 38px;
            }
          }
          .core-item-cont {
            padding-top: 30px;
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 38px;
          }
        }
      }
    }
  }
}


