.animate {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--purple);
  --accent: var(--white);

  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--background);
  color: var(--accent);
  overflow: hidden;

  .bannerBox {
    width: 600px;
    height: 200px;
    background-color: var(--blue);
    .banneritem {
      width: 200px;
      height: 200px;
      background-color: #fafafa;
    }
  }

  ul {
    li {
      list-style: none;
      text-align: center;
      margin-top: 20px;
      width: 300px;
      height: 90px;
      line-height: 90px;
      background-color: var(--dusk);
    }
  }

  .App {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
    text-align: center;
  }

  .App-logo {
    height: 80%;
    pointer-events: none;
  }

  .App-header {
    flex: 1;
    margin: 1rem 1rem 0;
    border-radius: 1rem;
    background-color: #282c34;
    min-height: 5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .kanban-board {
    flex: 10;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0 1rem 1rem;
  }

  .kanban-column {
    flex: 1 1;
    border: 1px solid gray;
    border-radius: 1rem;
  }
}
