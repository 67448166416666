.solution-card {
  margin: 0 auto;
  width: 1430px;
  height: auto;
  overflow: hidden;
  @mixin infoTitle {
    display: flex;
    align-items: center;
    height: 110px;
    .m-r-20 {
      margin-right: 20px;
    }
    .m-l-20 {
      margin-left: 20px;
    }
    .info-title-icon {
      width: 21px;
      height: 18px;
    }
    .info-title-text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #100606;
      line-height: 71px;
    }
  }
  .card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 218px;
    .title-icon {
      // width: 51px;
      // height: 43px;
    }
    .title-text {
      margin: 0 32px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 71px;
    }
  }
  .card-banner {
    width: 1430px;
    display: flex;
    .banner-item {
      width: 715px;
      // height: 534px;
    }
    .banner-info {
      width: 715px;
      // height: 534px;
      .info-title {
        @include infoTitle;
      }
      .info-title-cont {
        padding: 40px;
        width: 635px;
        height: 348px;
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
        overflow: hidden;
        background-color: #ea9c4c;
      }
    }
  }
  .card-list {
    .info-title {
      @include infoTitle;
    }
    .list-item {
      width: 100%;
      min-height: 50px;
      font-size: 22px;
      margin-left: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      .num {
        color: #ea9c4c;
      }
    }
  }
}
