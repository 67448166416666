.cont-list {
  display: flex;
  margin-top: 65px;
  width: calc(1920px -17px);
  height: 440px;
  .pure-box {
    margin-top: 290px;
    width: 222px;
    height: 150px;
    background-color: #ea9c4c;
  }

  .img-box {
    position: relative;
    width: 689px;
    height: 440px;
    background-color: #e4e2e2;
    .img-box-item {
      position: absolute;
      top: 73px;
      right: 115px;
      width: 459px;
    height: 293px;
    }
    .img-block {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 15px;
      height: 15px;
      background-color: #fca50d;
    }
  }

  .info-box {
    margin: 0 80px;
    width: 689px;
    height: 440px;
    .info-title {
      height: 130px;
      line-height: 130px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1a2420;
    }
    .info-line {
      display: flex;
      height: 5px;
      .line-y {
        height: 5px;
        width: 14px;
        background-color: #fca50d;
      }
      .line-g {
        margin: 0 5px;
        height: 5px;
        width: 36px;
        background-color: #ececfb;
      }
    }
    .info-text {
      margin-top: 66px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      // font-weight: bold;
      color: #666666;
      line-height: 32px;
    }
  }
}
