.pro {
  padding: 40px 0;
  .production {
    display: flex;
    margin: 0px auto;
    width: 1420px;
    min-height: 675px;
    background-color: #f7f7f7;
    border-bottom: 14px solid #ecc78b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
    .img-box {
      position: relative;
      margin: 80px 0 0 50px;
      width: 527px;
      height: 527px;
      background-color: #f5e9de;
      border-radius: 50%;
      overflow: hidden;
      .img-bg {
        width: 527px;
        height: 527px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .img-item {
        position: absolute;
        left: 102px;
        top: 89px;
        width: 346px;
        height: 339px;
      }
    }
  
    .info {
      margin: 80px 0 0 52px;
      width: 730px;
      .info-title {
        height: 118px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1a2420;
        line-height: 118px;
      }
      .info-line {
        width: 519px;
        height: 2px;
        background-color: #ecc78b;
      }
      .info-cont {
        margin-top: 60px;
        min-height: 400px;
        font-family: Microsoft YaHei;
        font-size: 16px;
      }
    }
  }
  .bottom-info {
    margin: 0 auto;
    position: relative;
    display: flex;
    padding-top: 7px;
    width: 1420px;
    min-height: 115px;
    background-color: #ecc78b;
    .title {
      margin-left: 51px;
      // width: 171px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
    }
    .content {
      // width: 945px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
      // padding-left: 50px;
    }
  }
}
