.news {
  width: calc(1920px - 17px);
  overflow: hidden;
  .top-cont {
    position: relative;
    width: 1920px;
    height: 500px;
    .common-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 500px;
    }
    .common-title {
      position: absolute;
      text-align: center;
      width: 1920px;
      padding-top: 281px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 3px 7px rgba(151, 151, 153, 0.5);
    }
  }

  .list {
    margin: 0 auto;
    width: 1420px;
    height: auto;
    cursor: pointer;
    .list-item {
      display: flex;
      width: 1420px;
      height: 320px;
      .list-img-box {
        position: relative;
        width: 420px;
        height: 250px;
        .list-img {
          width: 420px;
          height: 250px;
        }
        .list-time {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 130px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          // background-color: #ea9c4c;
        }
        .list-time-selected {
          // background-color: #ea9c4c;
        }
      }
      .list-info {
        margin-left: 46px;
        width: 914px;
        .list-title {
          line-height: 117px;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 550;
          color: #272636;
        }
        .list-cont {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 35px;
          height: 105px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; // 行数
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }

  .page-count {
    margin: 35px auto;
    width: 1420px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count {
      display: flex;
    }
    .go-count {
      display: flex;
    }
    .count-item {
      position: relative;
      margin: 0 5px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909ba7;
      background-color: #e8ebef;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 7px;
        height: 13px;
      }
    }
    .blank {
      background-color: transparent;
    }
    .count-item-selected {
      color: #ffffff;
      background-color: #EA9C4C;
    }
  }

  .detail {
    margin: 101px auto 242px;
    width: 1420px;
    height: auto;
  }
}
