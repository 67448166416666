.clinet-container {
  width: calc(1920px - 17px);
  margin: 0 auto;
  overflow: hidden;
  .client {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1420px;
    height: 84.68px;

    .client-box {
      margin: 0 auto;
      width: 1200px;
      height: 84.68px;
    }
    .swiper-side-item-class {
      display: block;
    }
    .swiper-side-item-class>img {
      // &:nth-child(2) {
      //   margin: 56px auto 0;
      //   display: block;
      //   width: 232px;
      //   height: 85px;
      // }
      // &:nth-child(3) {
      //   display: block;
      //   margin: 65px auto 0;
      //   width: 243px;
      //   height: 69px;
      // }
      // &:nth-child(4) {
      //   display: block;
      //   margin: 76px auto 0;
      //   width: 266px;
      //   height: 48px;
      // }
      // &:nth-child(5) {
      //   display: block;
      //   margin: 52px auto 0;
      //   width: 168px;
      //   height: 95px;
      // }
      width:240px;
      height: 84.68px;
    }
    .client-img{
      width: 300px;
      height: 84.68px;
    }
    .client-img1{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img2{
      width: 121.5px;
      height: 60px;
      padding: 0 20px;
    }
    .client-img3{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img4{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img5{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img6{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img7{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img8{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img9{
      width: 40.36661327px;
      height: 60px;
    }
    .client-img10{
      width: 40.36661327px;
      height: 60px;
    }
    // .client-img {
    //   &:nth-child(2) {
    //     margin: 56px auto 0;
    //     display: block;
    //     width: 232px;
    //     height: 85px;
    //   }
    //   &:nth-child(3) {
    //     display: block;
    //     margin: 65px auto 0;
    //     width: 243px;
    //     height: 69px;
    //   }
    //   &:nth-child(4) {
    //     display: block;
    //     margin: 76px auto 0;
    //     width: 266px;
    //     height: 48px;
    //   }
    //   &:nth-child(5) {
    //     display: block;
    //     margin: 52px auto 0;
    //     width: 168px;
    //     height: 95px;
    //   }
    // }

    .after {
      width: 30px;
      height: 30px;
    }
    .before {
      width: 30px;
      height: 30px;
    }
  }
}
