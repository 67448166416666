.product {
  position: relative;
  width: calc(1920px - 17px);
  overflow: hidden;
  .top-cont {
    position: relative;
    width: 1920px;
    height: 500px;
    .common-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 500px;
    }
    .common-title {
      position: absolute;
      text-align: center;
      width: 1920px;
      padding-top: 281px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 3px 7px rgba(151, 151, 153, 0.5);
    }
  }
  .tab {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1400px;
    height: 60px;
    .tab-item {
      width: 180px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      color: #A0A0A0;
      border: 1px solid #A0A0A0;
      cursor: pointer;
    }
    .tab-item-selected {
      color: #FCA50D;
      border: 1px solid #FCA50D;
    }
  }
}
