.container {
  width: calc(1920px - 17px);
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  .headBox {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(1920px - 17px);
    height: 124px;
    overflow: hidden;
    border-bottom: 1px solid #fff;
    z-index: 9999;
    // background-color: #f5f5f5;
    .logo {
      position: absolute;
      top: 20px;
      left: 252px;
      width: 142px;
      height: 90px;
    }
    .nav {
      position: absolute;
      top: 80px;
      left: 684px;
      width: 677px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .search {
      position: absolute;
      top: 78px;
      right: 326px;
      width: 15px;
      height: 15px;
    }
    .language {
      position: absolute;
      top: 70px;
      right: 248px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    span:hover {
      cursor: pointer;
    }
  }

  .footBox {
    position: relative;
    width: calc(1920px - 17px);
    height: 653px;
    overflow: hidden;
    .footBox-bg {
      position: absolute;
      width: 1920px;
      height: 653px;
      top: 0;
      left: 0;
    }
    .foot-logo {
      position: absolute;
      top: 7px;
      left: 286px;
      width: 169px;
      height: 106px;
    }
    .foot-cont {
      position: absolute;
      left: 647px;
      top: 70px;
      width: 553px;
      height: 450px;
      .foot-nav {
        width: 553px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .conntct-way {
        margin-top: 200px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 60px;
      }
    }
    .foot-circle {
      position: absolute;
      top: 51px;
      left: 1385px;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      // border: 4px solid #eb9b4d;
    }
    .foot-ins {
      position: absolute;
     width: 75px;
     height: 75px;
     border-radius: 50%;
    }
    .foot-fb-pos {
      left: 1505px;
    }
    .foot-fb {
      position: absolute;
     width: 75px;
     height: 75px;
     border-radius: 50%;
    }
    .foot-qy {
      position: absolute;
      top: 300px;
      left: 1385px;
      width: 204px;
      height: 204px;
    }
  }
}
