.connect {
  width: calc(1920px - 17px);
  overflow: hidden;
  .top-cont {
    position: relative;
    width: 1920px;
    height: 500px;
    .common-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 500px;
    }
    .common-title {
      position: absolute;
      text-align: center;
      width: 1920px;
      padding-top: 281px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 3px 7px rgba(151, 151, 153, 0.5);
    }
  }

  .map {
    position: relative;
    margin: 0 auto;
    width: 1416px;
    height: 682px;
    .map-img-bg {
      position: absolute;
      width: 1416px;
      height: 682px;
    }
    .address {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 271px;
      height: 106px;
      line-height: 106px;
      text-align: center;
      font-size: 42px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      background-color: #ea9c4b;
    }
  }

  .addressbox {
    margin: 20px auto;
    width: 1415px;
    height: auto;
    .addressbox-item {
      display: flex;
      flex-wrap: wrap;
      width: 1415px;
      height: auto;
      border-top: 2px dashed #ccc;
      padding: 25px 0;
      .title {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #323232;
        line-height: 30px;
      }
      .cont {
        display: inline;
        font-size: 16px;
        font-family: Microsoft YaHei;
        // font-weight: bold;
        color: #6d6d6d;
        line-height: 60px;
      }
      .cont1 {
        // width: 1415px;
        // display: inline;
        font-size: 16px;
        font-family: Microsoft YaHei;
        // font-weight: bold;
        color: #6d6d6d;
        line-height: 60px;
        width: 1415px;
      }
      div {
        &:nth-child(1) {
          width: 310px;
        }
        &:nth-child(1) {
          width: 800px;
        }
        &:nth-child(1) {
          width: 280px;
        }
      }
    }
    .share {
      display: flex;
      flex-wrap: wrap;
      width: 1415px;
      height: auto;
      border-top: 2px dashed #ccc;
      padding: 43px 0 95px;
      .share-img {
        margin-right: 30px;
        width: 47px;
        height: 47px;
      }
    }
  }

  .form {
    position: relative;
    width: 1920px;
    height: 844px;
    background-color: #f7f7f7;
    .left-img {
      position: absolute;
      top: 122px;
      left: 0;
      width: 631px;
      height: 567px;
    }
    .formbox {
      position: absolute;
      top: 102px;
      left: 687px;
      width: 982px;
      height: auto;
      .title {
        font-size: 36px;
        text-indent: 38px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 68px;
      }

      .ipt-1 {
        width: 982px;
        display: flex;
        justify-content: space-between;
        .ipt-1-item {
          width: 316px;
          min-height: 116px;
        }
        .ipt-title {
          font-size: 22px;
          position: relative;
          width: 316px;
          height: 58px;
          line-height: 58px;
          &::before {
            position: absolute;
            left: -15px;
            top: 5px;
            content: '*';
            color: #d7a47c;
          }
        }
        .ipt-item {
          display: block;
          width: 316px;
          height: 58px;
          font-size: 22px;
          text-indent: 10px;
          outline: none;
          border: none;
          line-height: 58px;
          background-color: #ffffff;
        }
        .ipt-item::placeholder{
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #acacac;
        }
        .ipt-textarea {
          width: 982px;
          height: 240px;
        }
        .ipt-textarea::placeholder {
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #acacac;
        }
      }
      .btn-submit {
        margin-top: 18px;
        height: 58px;
        line-height: 58px;
        width: 982px;
        background-color: #ea9c4b;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
      .aob{
        display: none;
      }
    }
  }
}
